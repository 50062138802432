import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import Benefits from "../components/benefits";
import VitaminMainCTA from "../components/vitaminMainCTA";
import Follow from "../components/follow";
import { getLabels } from "../utils/actions";
import SEO from "../components/seo";

const BlogPage = ({ data }) => {
  const [lang, setLang] = React.useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );

  const [label, setLabel] = React.useState({});


  const articleTitle = data.allLabels.edges.find(
    ed => ed.node.label === "article-title"
  );
  const articleText = data.allLabels.edges.find(
    ed => ed.node.label === "article-text"
  );
  const readMore = data.allLabels.edges.find(
    ed => ed.node.label === "read_more"
  );


  const header = {
    title: articleTitle.node[lang],
    description: () => <span>{articleText.node[lang]},</span>,
  };
  return (
    <Layout>
      <SEO title="" />
      <div className="container blogPosts">
        {data && data.allContentfulBlogPost && (
          <Benefits
            lang={lang}
            header={header}
            benefits={data.allContentfulBlogPost.edges.map(n =>
              blog2benefits(n.node, lang, readMore)
            )}
            extraClass="vitaminPage"
            startTextLeft={true}
          />
        )}
      </div>
      <VitaminMainCTA data={data.allLabels.edges} />
      <Follow data={data.allLabels.edges} lang={lang} />
    </Layout>
  );
};

export default BlogPage;

const blog2benefits = (item, lang, readMore) => {
  return {
    title: item[lang === "gr" ? "title" : "titleInEnglish"],
    description: () =>
      documentToReactComponents(
        item[lang === "gr" ? "text" : "textInEnglish"].json.content[0]
      ),
    ctaText: readMore && readMore.node[lang],
    ctaLink: `/blog/${item.title}-${item.id}/`,
    ctaArrow: true,
    ctaBG: "black",
    image: item.mainImage.fluid.src,
  };
};

export const query = graphql`
  query {
    allContentfulBlogPost(
      sort: { fields: [date], order: DESC }
      filter: { membersonly: { ne: true } }
    ) {
      edges {
        node {
          id
          title
          titleInEnglish
          childContentfulBlogPostTextRichTextNode {
            fields {
              readingTime {
                minutes
              }
            }
          }
          text {
            json
          }
          textInEnglish {
            json
          }
          mainImage {
            file {
              url
            }
            fluid {
              src
            }
          }
        }
      }
    }
    allLabels {
      edges {
        node {
          label
          gr
          en
        }
      }
    }
  }
`;
